import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import clsx from 'clsx';

import { fetchAnnotatedQuestion } from 'store/modules/annotatedQuestion';
import { toggleFollowUp } from 'store/modules/followUpState';
import Avatar from 'components/avatar/Avatar';

import styles from './user-message.scss';
import messageStyles from './message.scss';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SchoolIcon from '@mui/icons-material/School';
import { chatIconButtonStyle } from './BaseMessage';
import Logo from '../logo/Logo';
import React, { useState } from 'react';
import SaveRephraseGroundTruthForm from '../../forms/SaveRephraseGroundTruthForm';

import { layouts } from 'config/constants';

import { CustomModal, InfoTooltip } from 'components/v3';

export function UserMessage(props) {
  const {
    t,
    chatMessages,
    id,
    textAnswer,
    isRephraseMessage,
    isDiscovery,
    originalQuestion,
    hasWritePermission,
    isSuperUserOrHigher
  } = props;
  const [displayRephraseGroundTruthModal, setDisplayRephraseGroundTruthModal] = useState(false);
  const classes = chatIconButtonStyle();
  const answerToQuestionMessage = chatMessages.find(msg => msg.localQuestionId && msg.localQuestionId === id);
  const questionId = answerToQuestionMessage
    ? answerToQuestionMessage.originalQuestionId || answerToQuestionMessage.questionId
    : 'no id at this point';

  let contextMessage;
  if (answerToQuestionMessage !== undefined && answerToQuestionMessage.referenceTo) {
    const { answerId, interpretationId } = answerToQuestionMessage.referenceTo;
    contextMessage = chatMessages.find(
      msg =>
        msg.id &&
        msg.answerId === answerId &&
        ((msg.answer && msg.answer.interpretationId === interpretationId) || // normal answer message
          (msg.interpretationIds && msg.interpretationIds.includes(interpretationId))) // board answer message
    );
  }

  let contextInterpretationId, contextPartialAnswerId, contextAnswerId, contextMessageId;
  if (contextMessage !== undefined) {
    contextInterpretationId = answerToQuestionMessage.referenceTo.interpretationId;
    contextPartialAnswerId = answerToQuestionMessage.referenceTo.partialAnswerId;
    contextAnswerId = answerToQuestionMessage.referenceTo.answerId;
    contextMessageId = contextMessage.id;
  }

  const shouldShowEditButton = !isDiscovery;
  const editButton = (
    <InfoTooltip text={t('tooltip.edit')} placement="left">
      <IconButton
        classes={classes}
        className={styles.editButton}
        onClick={() => {
          if (contextMessage) {
            // this is true only if it's followUp question
            props.scrollTo(contextMessage.id);
            // use a timeout here so we actually register a scrolling event and then handle
            // correct ordering of first scrolling and then setting the focus on the input box
            // see Chat.js
            setTimeout(() => props.dispatch(fetchAnnotatedQuestion(questionId, textAnswer)), 200);
          } else {
            props.dispatch(fetchAnnotatedQuestion(questionId, textAnswer));
          }

          // TODO: all props are here undefined because of MultiAnswer
          if (contextInterpretationId && contextAnswerId && contextMessageId) {
            props.dispatch(
              toggleFollowUp(contextInterpretationId, contextPartialAnswerId, contextAnswerId, contextMessageId)
            );
          }
        }}
      >
        <EditIcon />
      </IconButton>
    </InfoTooltip>
  );

  const shouldShowTeachButton = isSuperUserOrHigher && hasWritePermission && !isDiscovery;
  const teachButton = (
    <>
      <InfoTooltip text={t('save-new-rephrase-ground-truth.title')} placement="left">
        <IconButton
          classes={classes}
          className={styles.teachButton}
          onClick={() => setDisplayRephraseGroundTruthModal(true)}
        >
          <SchoolIcon />
        </IconButton>
      </InfoTooltip>
    </>
  );
  const referenceToText = (
    <div className={styles.referenceToText}>
      <Trans i18nKey="reference-to-text">
        This Question is a FollowUp on
        <span className={styles.linkStyle} onClick={() => props.scrollTo(contextMessage.id)}>
          this Answer
        </span>
      </Trans>
    </div>
  );

  // For the rephrase message, we show the Veezoo logo instead of the user's avatar.
  const avatarIcon = isRephraseMessage ? (
    <div className={`${styles.userIcon} ${messageStyles.hideOnSmallScreens}`}>
      <Logo displayShortVersion alt="Veezoo logo" />
    </div>
  ) : (
    <div className={`${styles.userIcon} ${messageStyles.hideOnSmallScreens}`}>
      <Avatar size={50} />
    </div>
  );

  const userMessage = (
    <>
      <div className={styles.container}>
        <div className={styles.placeholder} />
        <div className={styles.messageUser}>
          {editButton}
          <div className={styles.messageBody} data-test="userMessageText">
            {textAnswer.split('\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}
                {i < textAnswer.split('\n').length - 1 && <br />}
              </React.Fragment>
            ))}
          </div>
          {contextMessage ? referenceToText : ''}
        </div>
        {avatarIcon}
      </div>
    </>
  );

  const rephraseMessage = (
    <>
      <div className={clsx(styles.containerParaphase, isDiscovery ? styles.reverseMessageAndAvatar : '')}>
        {!isDiscovery && <div className={styles.placeholder} />}
        <div className={clsx(styles.messageRephrase, isDiscovery ? styles.alignLeft : '')}>
          {shouldShowEditButton && editButton}
          {shouldShowTeachButton && teachButton}
          <div className={styles.messageRephraseBody}>
            <b>
              {t('rephrase.reformulated-to')}: <br />
            </b>
            <span data-test="userMessageText">{textAnswer}</span>
          </div>
          <aside className={styles.footer}>
            <CustomModal
              layout={layouts.veezoo}
              title={t('save-new-rephrase-ground-truth.title')}
              open={displayRephraseGroundTruthModal}
              content={
                <SaveRephraseGroundTruthForm
                  originalQuestion={originalQuestion}
                  rephrasedQuestion={textAnswer}
                  onClose={() => setDisplayRephraseGroundTruthModal(false)}
                  t={t}
                />
              }
              onClose={() => setDisplayRephraseGroundTruthModal(false)}
              hideButtons
            />
          </aside>
        </div>
        {avatarIcon}
      </div>
    </>
  );

  /**
   * end helper components.
   */

  return (
    <div className={styles.wrapper} data-test="userMessage" data-question-id={answerToQuestionMessage?.questionId}>
      {isRephraseMessage ? rephraseMessage : userMessage}
    </div>
  );
}

const mapStateToProps = state => ({
  chatMessages: state.chatMessages,
  isSuperUserOrHigher: state.user.isSuperUserOrHigher,
  hasWritePermission: state.knowledgeGraphMeta.meta.hasWritePermission
});

export default withTranslation('veezoo', { withRef: true })(connect(mapStateToProps)(UserMessage));
